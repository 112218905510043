const Footer = () => {
    const dateYear = new Date();
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                    <div className="d-flex justify-content-center align-items-center" >
                        <p className="fs-6">©{ dateYear.getFullYear()} TheDevPro LLC. All rights reserved.</p>
                    </div>
                </div>
                </div>
            </footer>
        </>
    )
}


export default Footer;