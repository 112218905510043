import './App.css';
import Header from './header';
import Home from '../home/home';
import Footer from './footer';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  return (
    <>
        <Header subtitle="TheDevPro"></Header>
        <Home />
       <Footer/>
    </>
  );
}

export default App;
