import logo from "./logo.png";

type Args = {
    subtitle: string
}

const Header = ({ subtitle }: Args) => {
    return (
        <>
        <header>
           
        <nav className="top-nav">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-auto">
                            <p>
                                <i className="bx bxs-envelope"></i>
                                <span>hello@thedevpro.io</span>
                            </p>
                            <p>
                                <i className="bx bxs-envelope"></i>
                                <span>+1 (801) 376.2721</span>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-light bg-white ">
                <div className="container">
                    <a className="navbar-brand" href="#">
                        <img src={logo} className='logo' alt='logo' />
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse ms-auto" id="navbarNavDropdown">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Pricing</a>
                            </li> */}

                        </ul>
                    </div>
                </div>
            </nav>
           
        </header>
        </>
    )
}

export default Header;