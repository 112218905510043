

import templateWeb01 from "../assets/website-floatspa19.jpg";
import templateWeb02 from "../assets/website-noemiscafe.jpg";
import templateWeb03 from "../assets/website-mywhiskeywillys.png";
import iconWeb01 from "../assets/icon-01.jpg";


const Home = () => {


    //const { data } = useFetchHome();


    return (
        <>
            <div className="container-fluid-custom">
                <div className="hero about">
                    <div className="center-vertically">
                        <div className="wrapper main-content">
                            <h1 className="display-5">On Shopify,</h1>
                            <h2>We create stunning shops that boost our clients' revenue.</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-5 custom-fluid-bg" >

            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-3 col-md-6  col-sm-12 mt-5">
                        <div className="card" >
                            <img src={iconWeb01} className="card-img-top" alt="..."/>
                            <div className="card-body card-body-detail">
                                <h5 className="card-title">Branding</h5>
                                <p className="card-text">There is more to creating a distinctive brand than just a logo. A successful brand is built by effectively communicating your brand identity, your values, and your product. Your brand serves as both a promise to your consumers and the character of your company.</p>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
                        <div className="card">
                        <img src={iconWeb01} className="card-img-top" alt="..."/>
                                <div className="card-body card-body-detail">
                                    <h5 className="card-title">Digital Marketing</h5>
                                    <p className="card-text">The more targeted your content marketing efforts, the more quality leads you will generate. People that call you are already interested in your services. We use a variety of tailored techniques to guarantee that quality visitors sees your website.</p>
                                    {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                                </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6  col-sm-12 mt-5">
                    <div className="card" >
                    <img src={iconWeb01} className="card-img-top" alt="..."/>
                            <div className="card-body card-body-detail">
                                <h5 className="card-title">Website Design</h5>
                                <p className="card-text">We design and build bespoke websites with revenue-generating potential. To create well-crafted online solutions, our team mixes user-centered design with cutting-edge technology. We will guide your visitors' attention where it needs to be using our skills in color theory, picture selection, and typography.</p>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3  col-md-6  col-sm-12 mt-5">
                    <div className="card" >
                    <img src={iconWeb01} className="card-img-top" alt="..."/>
                            <div className="card-body card-body-detail">
                                <h5 className="card-title">eCommerce</h5>
                                <p className="card-text">Business evolves quickly in the digital era. We offer simple systems that are customizable and will meet the long-term objectives of your eCommerce business. Our team will create an intuitive online shop that allows you to manage inventory, track orders, and keep customers, all with personalized branding to meet your specific needs.</p>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div> </div>
                </div>
            </div>

            </div> 

            <div className="container mt-5">
                <div className="row">
                    <div className="col-6 d-flex flex-column justify-content-center">
                        <h3>Design and development of Shopify</h3>
                        <p>Our knowledge of ecommerce best practices benefits our customers. Not only are our website designs stunning, but they also attract clients.</p>
                    </div>

                    <div className="col-6">
                        <img src={templateWeb01} className='rounded-3  mx-auto d-block img-fluid img-thumbnail' width={350} alt='logo' />
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-6">
                        <img src={templateWeb02} className='rounded-3  mx-auto d-block img-fluid img-thumbnail' width={350} alt='logo' />
                    </div>
                    <div className="col-6 d-flex flex-column justify-content-center">
                        <h3>Store migrations</h3>
                        <p>We've had success transferring online stores from Magento, BigCommerce, and other systems to Shopify.</p>
                        <p>Replatforming can be a difficult and intimidating process. We'll support you every step of the way to provide a simple process that eventually supports the expansion of your organization.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-6 d-flex flex-column justify-content-center">
                        <h3>Custom web designs</h3>
                        <p>We use data-driven insights and years of expertise to help you develop your brand. We will assist you in engaging your audience and generating a lasting impression.
                        </p>
                        <p>In today's digital age, expanding into new areas and developing your business requires engaging with existing and future clients.
                        </p>
                        <p>Given the vast worldwide market, people like to inspect and assess several brands to pick which one is ideal for them in terms of features and cost.
                        </p>
                        <p>You may gain consumer confidence and trust by creating an interesting, one-of-a-kind web page design. You will lose the capacity to reach out to your target market and promote your business if you do not have an internet presence.
                        </p>
                        <p className="mt-5">View our portfolio <i className="bi bi-arrow-right-circle"></i></p> 
                    </div>
                    <div className="col-6">
                        <img src={templateWeb03} className='rounded-3  mx-auto d-block img-fluid img-thumbnail' width={350} alt='logo' />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Home;